.features-tiles-item-image {
	display: inline-flex;
	border-radius: 50%;
	background-color: get-color(dark, 2);
	height: 64px;
    width: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #7f7f7f;
}
