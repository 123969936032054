.hero-logo {
    max-height: min(40vh, 500px) !important;
}

.pubble-cw-powered-container {
    display: none;
}

.pubble-sb-bubble {
    display: none;
}